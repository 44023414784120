<template>
  <div class="tab-content col-lg-10 col-md-10 ">
    <div class="iq-card-body p-0">
      <div class="row">
        <!-- b-statements__aside -->
        <OtherLeftMenu :menuId="selectedId"></OtherLeftMenu>
        <div class="col-lg-9">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('label.chatSetting') }}</h4>
            </template>
            <template v-slot:body>
              <div class="form-group">
                <label class="form-label" for="colFormLabel">{{ $t('label.hideGroupMsg') }}</label>
                <div class="float-end form-check form-switch">
                  <input v-model="form.is_hide_send" class="form-check-input" type="checkbox" value="0" id="flexSwitchCheckChecked" />
                </div>
                <!-- {{form}} -->
                <!-- <p class="form-control">
                    啟用對話的排序，以便不會受到發出的群發信息的影響
                  </p> -->
              </div>
            </template>
          </iq-card>
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('label.welcomeMessage') }}</h4>
            </template>
            <template v-slot:body>
              <div>
                <label class="form-label" for="colFormLabel">{{ $t('label.hideGroupMsgDesc') }}</label>

                <div class="float-end form-check form-switch">
                  <input v-model="form.is_default_send" class="form-check-input" type="checkbox" value="0" id="flexSwitchCheckChecked" />
                </div>
              </div>
              <div v-if="isEdit == false" class="chat d-flex other-user">
                <div class="chat-detail">
                  <div class="chat-message">
                    <div class="img-wrapper" style="width: 50%;text-align: left;">
                      <a v-for="(item, index) in form.message_template_media" :key="index" href="javascript:void();">
                        <img :src="item.message_template_media_img" alt="post-image" class="img-fluid rounded w-70 pb-2" />
                      </a>
                    </div>
                    <div class="text-wrapper">
                      <p style="text-align: -webkit-left">
                        {{form.message_template_content}}
                      </p>
                      <!-- <p style="text-align: -webkit-left">
                          Welcome to join Nana's little world ❤️ Remember to
                          take the subscription gift video with me 🥰 chat with
                          me more and support me in the future🥳
                        </p> -->
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="isEdit == true" id="edit" class="pb-5">
                <div>
                  <!-- {{message_template_media}} -->
                  <el-upload list-type="picture-card" :headers="{'authorization':token}" :accept="commomData.uploadOption.accept" v-model:file-list="message_template_media"
                    :data=" { 'type': 'message_template' }" :action="commomData.uploadOption.url" :on-success="handleAvatarSuccess" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
                    <el-icon>
                      <Plus />
                    </el-icon>

                    <template #tip>
                      <div class="el-upload__tip">
                        jpg/png files with a size less than 2MB
                      </div>
                    </template>
                  </el-upload>
                  <el-dialog v-model="dialogVisible">
                    <img w-full :src="dialogImageUrl" alt="Preview Image" />
                  </el-dialog>
                  <!-- <div class=" d-flex justify-content-start input-group">
                    <div class=" d-flex ">
                      <img src="@/assets/images/page-img/1108x1478_79b965b280c8e1c56ac22f398cbb9e4d.jpeg"
                        class="img-rounded rounded" style="min-width: 128px;max-width: 100%;height: 128px;"
                        alt="Cinque Terre">
                      <button type="button" class="btn btn-circle-sm m-1"><i class="fas fa-times me-0"></i></button>
                    </div>
                    <div class=" d-flex ">
                      <img src="@/assets/images/page-img/1108x1478_79b965b280c8e1c56ac22f398cbb9e4d.jpeg"
                        class="img-rounded rounded" style="min-width: 128px;max-width: 100%;height: 128px;"
                        alt="Cinque Terre">
                      <button type="button" class="btn btn-circle-sm m-1"><i class="fas fa-times me-0"></i></button>
                    </div>
                  </div> -->

                </div>

                <div class="col-sm-12 d-flex justify-content-center input-group">
                  <textarea class="form-control" v-model="form.message_template_content" rows="5"></textarea>
                  <span class="invalid-feedback">{{ $t('messages.required') }}</span>
                </div>
              </div>
              <div v-if="isEdit == false" id="edit-btn" class="d-flex justify-content-end pt-2 pb-5">
                <!-- <button type="button" class="btn btn-secondary me-2">
                    {{ $t('button.del') }}
                  </button> -->
                <button @click="isEdit = true" type="button" class="btn btn-primary me-2">
                  {{ $t('button.edit') }}
                </button>
              </div>
              <div v-if="isEdit == true" id="finish-btn" class="d-flex justify-content-end pt-2 pb-5">
                <button @click="isEdit = false" type="button" class="btn btn-secondary me-2">
                  {{ $t('button.cancel') }}
                </button>
                <button @click="onSubmit" type="button" class="btn btn-primary me-2">
                  <i v-show="commomData.btnLoader" class="fa fa-spinner fa-spin"></i>
                  {{ $t('button.submit')}}
                </button>
              </div>
            </template>
          </iq-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OtherLeftMenu from '../User/Components/OtherLeftMenu'
import { addMessageTemp, getMessageTemp, delMessageTemp } from '@/api/message'
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { ref, onMounted, getCurrentInstance, computed } from 'vue'
import { mapGetters } from 'vuex'
import { Plus } from '@element-plus/icons-vue'
import { commomData } from '@/utils/commonVmodel'

export default {
  name: 'ChatSeting',
  props: ['menuId'],
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState',
      isPlan: 'User/isPlanState'
    })
  },
  components: {
    OtherLeftMenu,
    Plus
  },
  setup() {
    const { proxy } = getCurrentInstance()

    let toast$ = proxy.$toast
    const v$ = useVuelidate()
    const dialogVisible = ref(false)
    const dialogImageUrl = ref('')
    const btnLoader = ref(false)
    const isEdit = ref(true)

    const createFormData = () => ({
      is_default_send: 0,
      is_hide_send: 0,
      message_template_content: '',
      message_template_media: []
    })

    let form = ref(createFormData())
    const getRows = () => {
      getMessageTemp()
        .then(res => {
          console.log(res)
          if (res.data !== '') {
            form.value = res.data
            message_template_media.value = res.data.message_template_media
          }
        })
        .catch(err => {
          console.log(err)
          toast$.show(err.response.data.message)
        })
    }
    const onDel = r => {
      delMessageTemp(r)
        .then(res => {
          console.log(res)
          getRows()
        })
        .catch(err => {
          console.log(err)
          toast$.show(err.response.data.message)
        })
    }
    const onSubmit = async () => {
      // v$.value.form.$touch()
      // if (v$.value.form.$error) {
      //   return
      // }
      const d = {
        is_default_send: form.value.is_default_send,
        is_hide_send: form.value.is_hide_send,
        message_template_media: form.value.message_template_media,
        message_template_content: form.value.message_template_content
      }
      await addMessageTemp(d)
        .then(res => {
          toast$.show(res.message)
          btnLoader.value = false
          isEdit.value = false
          getRows()
        })
        .catch(err => {
          btnLoader.value = false
          console.log(err)
          // toast$.show(err.response.data.message)
        })
    }
    const handleRemove = (uploadFile, uploadFiles) => {
      console.log(uploadFile, uploadFiles)
      form.value.message_template_media = form.value.message_template_media.filter(
        element => {
          const c = !element.message_template_media_url
            ? element.message_template_media_img
            : element.message_template_media_url
          return c !== uploadFile.url
        }
      )
    }

    const handlePictureCardPreview = uploadFile => {
      dialogImageUrl.value = !uploadFile.url
      dialogVisible.value = true
    }
    const handleAvatarSuccess = (response, uploadFile) => {
      console.log(response, uploadFile)
      const d = {
        message_template_media_url: response.file.file_url,
        message_template_media_img: response.file.file_path,
        message_template_media_id: ''
      }
      form.value.message_template_media.push(d)
    }

    const message_template_media = computed(() => {
      return form.value.message_template_media.map((item, index) => {
        console.log(index)
        return {
          name: Math.random(),
          url: !item.message_template_media_url
            ? item.message_template_media_img
            : item.message_template_media_url
        }
      })
    })
    onMounted(() => {
      getRows()
    })
    return {
      v$,
      toast$,
      onDel,
      onSubmit,
      form,
      dialogVisible,
      dialogImageUrl,
      handlePictureCardPreview,
      handleRemove,
      handleAvatarSuccess,
      isEdit,
      message_template_media
    }
  },
  data() {
    return {
      selectedId: 2,
      commomData: commomData()
    }
  },
  validations() {
    return {
      form: {
        message_template_content_: { required }
      }
    }
  }
}
</script>
<style scoped>
.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

<style>
.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>