import request from '../utils/request'

// API-F37 - [MessageController] message/template/edit 編輯聊天設定
export function addMessageTemp(data) {
  return request({
    url: `/message/template/edit`,
    method: 'put',
    data
  })
}

// API-F38 - [MessageController] message/template/detail 詳細聊天設定
export function getMessageTemp() {
  return request({
    url: `/message/template/detail`,
    method: 'get'
  })
}

// API-F39 - [MessageController] message/template/delete 刪除聊天設定
export function delMessageTemp() {
  return request({
    url: `/message/template/delete`,
    method: 'delete'
  })
}
